import * as THREE from 'three';
import {DrawingGlobals, HISTORY, LABELS, POLYGONS, VERTICES} from "../Global/variables";
import {COLORS, LINES} from "../Global/materials";
import {createEdges, createVertex, earClipping} from "./Drawing";
import {getGeoCenter, removeLineMeshes} from "../Global/functions";
import {createNameLabel} from "./Labels";
import {calculatePitch, getAzimuth, getPolyArea, getPolyPitch} from "./AreaPitchAzimu";
import {refLineSnap, showReferenceLine} from "./Snaps/ReferenceLine";
import {showAngle, snap90Deg} from "./Snaps/SnapTo90";
import {vertexSnap} from "./Snaps/VertexSnap";
import {getSnapPoint} from "./Snaps/Snaps";
import {selectSegment} from "./Functions";

import * as Details from "./AreaPitchAzimu";

class SegmentManager {
    constructor(scene, stopDrawing, updateSegmentList, selectedIndices, setSelectedIndices, setPolygonDetails,) {
        this.scene = scene;
        this.stopDrawing = stopDrawing;
        this.snapVertex = null;
        this.snap90 = null;
        this.snapRefLine = null;
        this.snapPoint = null;
        this.lastMouseIntersect = null;
        this.updateSegmentList = updateSegmentList;
        this.selectedIndices = selectedIndices;
        this.setSelectedIndices = setSelectedIndices;
        this.setPolygonDetails = setPolygonDetails;
    }

    handleMouseDown(point) {
        if (this.snapPoint) {
            point = this.snapPoint;
            this.snapPoint = null;
        }
        if (DrawingGlobals.intersectionPoints.length > 2 && point.distanceTo(DrawingGlobals.intersectionPoints[0]) < 0.5) {
            DrawingGlobals.lineMeshes.slice(-1)[0].material.color.setHex(COLORS.red);
            this.completeShape()
        } else {
            DrawingGlobals.intersectionPoints.push(point);
            this.drawLine();
        }
        HISTORY.length = 0;
    }

    handleMouseMove(point) {
        this.lastMouseIntersect = point;
        if (DrawingGlobals.intersectionPoints.length === 0) {
            this.snapVertex = vertexSnap(this.snapVertex, point)
            if (this.snapVertex) {
                this.snapVertex.material.opacity = 1;
                if (this.snapVertex.position.distanceTo(point) <= 0.18) {
                    this.snapPoint = this.snapVertex.position;
                } else {
                    this.snapPoint = null;
                }
            } else {
                this.snapPoint = null;
            }
        }

        if (DrawingGlobals.intersectionPoints.length >= 1) {
            this.updateLine(point)

            if (DrawingGlobals.intersectionPoints.length >= 2) {
                this.snapRefLine = refLineSnap(this.snapRefLine, point)
                if (this.snapRefLine) {
                    showReferenceLine(this.snapRefLine);
                }
                this.snap90 = snap90Deg(this.snap90, point)
            }
            this.snapVertex = vertexSnap(this.snapVertex, point)
            this.snapPoint = getSnapPoint(this.snapPoint, this.snapVertex, this.snap90, point)
            showAngle(this.snapPoint)
            this.updateLine(this.snapPoint);
        }

    }

    drawLine() {
        const point = DrawingGlobals.intersectionPoints.slice(-1)[0];
        const geometry = new THREE.BufferGeometry().setFromPoints([point, point]);
        const line = new THREE.LineSegments(geometry, LINES.drawingLineMaterial);
        line.frustumCulled = false;
        this.scene.add(line);
        DrawingGlobals.lineMeshes.push(line);
    }

    updateLine(point) {
        const positionArray = DrawingGlobals.lineMeshes.slice(-1)[0].geometry.attributes.position.array;
        positionArray[3] = point.x;
        positionArray[4] = point.y;
        positionArray[5] = point.z;
        DrawingGlobals.lineMeshes.slice(-1)[0].geometry.attributes.position.needsUpdate = true;
    }

    removeLines() {
        removeLineMeshes(this.scene);
    }

    completeShape() {
        LABELS.angle.visible = false;
        this.removeLines();
        this.addShape(DrawingGlobals.intersectionPoints);
        selectSegment(this.selectedIndices, POLYGONS.length - 1, this.setSelectedIndices, this.setPolygonDetails)
        this.stopDrawing();
    }

    addShape(coordinates) {
        const v = coordinates.map(cord => new THREE.Vector3(cord.x, cord.y, cord.z));
        let polygonMesh = earClipping(v);

        for (let i of coordinates) {
            let vertex = createVertex(i);
            DrawingGlobals.verticesMesh.push(vertex)
            polygonMesh.add(vertex);
        }
        const {edgeData, edgeWireframe,setBackData} = createEdges(v, this.scene);
        let setBacks = { 'isSet':false, 'edgesData':setBackData}

        const polygonCenter = getGeoCenter(polygonMesh)
        const segmentLabel = createNameLabel(polygonCenter);

        polygonMesh.add(edgeWireframe)
        this.scene.add(polygonMesh);
        this.scene.add(segmentLabel);
        let details = this.getDetails(edgeData,polygonMesh);
        const segmentData = {
            shape: polygonMesh,
            edges: edgeData,
            label: segmentLabel,
            obstructions: [],
            details: details,
            setBacks: setBacks
        }
        POLYGONS.push(segmentData);

        let uiData = {label: segmentLabel.element.innerText, details: details, obstructions: []}
        this.updateSegmentList((oldPolygons) => [...oldPolygons, uiData]);
        this.cleanDrawingArray();

    }

    getDetails(edges) {

        let res = calculatePitch(edges);
        let pitch = res.pitch_angle
        let area = getPolyArea(edges).toFixed(2);
        let azimu = null;
        azimu = getAzimuth(res.p2, res.p1).toFixed(2);
        return {pitch, area, azimu}
    }

    cleanDrawingArray() {
        //stores vertices mesh for all added polygons at their respective index....
        VERTICES.push([...DrawingGlobals.verticesMesh]);
        DrawingGlobals.intersectionPoints = [];
        DrawingGlobals.verticesMesh = [];
        DrawingGlobals.lineMeshes = [];
    }

    undo() {
        LINES.referenceLine.visible = false;
        LABELS.angle.visible = false;
        let point, lastLine, lastVertex = null;

        if (DrawingGlobals.intersectionPoints.length > 0) {
            point = DrawingGlobals.intersectionPoints.pop();
        }

        if (DrawingGlobals.lineMeshes.length > 0) {
            lastLine = DrawingGlobals.lineMeshes.pop();
            this.scene.remove(lastLine);
            lastLine.geometry.dispose();
        }

        if (DrawingGlobals.verticesMesh.length > 0) {
            lastVertex = DrawingGlobals.verticesMesh.pop();
            this.scene.remove(lastVertex);
        }

        if (DrawingGlobals.intersectionPoints.length === 0) {
            HISTORY.length = 0;
            this.stopDrawing();
        } else if (point || lastLine || lastVertex) {
            this.updateLine(this.lastMouseIntersect);
            HISTORY.push({
                "point": point,
                "lastLine": lastLine,
                "lastVertex": lastVertex,
                "lastPoint": this.lastMouseIntersect
            });
        }
    }

    redo() {
        if (HISTORY.length > 0) {
            let redoThis = HISTORY.pop();

            if (redoThis.point) {
                DrawingGlobals.intersectionPoints.push(redoThis.point);
            }

            if (redoThis.lastLine) {
                this.updateLine(DrawingGlobals.intersectionPoints[DrawingGlobals.intersectionPoints.length - 1])
                DrawingGlobals.lineMeshes.push(redoThis.lastLine);
                this.scene.add(redoThis.lastLine);
            }

            this.updateLine(this.lastMouseIntersect);
        }
    }
}

export default SegmentManager;
