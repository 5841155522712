import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js';
import * as THREE from 'three';
import { DEV_MOD, LAT, LNG, MODEL_URL } from '../../helpers/CONSTANT';
import {VARS} from "../Global/variables";
import {MTLLoader} from "three/examples/jsm/loaders/MTLLoader";

export class ModelManager {

    constructor(scene, setModelProgress, currentDesign) {
        this.scene = scene;
        this.setModelProgress = setModelProgress;
        this.currentDesign = currentDesign;
        this.modelMeshs = [];

        this.lat = parseFloat((DEV_MOD) ? LAT : currentDesign?.latitude);
        this.lng = parseFloat((DEV_MOD) ? LNG : currentDesign?.longitude);
        this.boxMinz = 0;

        this.loadingManager = new THREE.LoadingManager();
        this.loadingManager.onProgress = this.onProgress.bind(this);
        this.loadingManager.onError = this.onError.bind(this);

        this.loadModel();
        this.addFloor();
        this.addLights();

        return this.modelMeshs
    }

    loadModel() {
        let loader = new GLTFLoader(this.loadingManager);

        if (DEV_MOD) {
            loader = new MTLLoader(this.loadingManager);
            let modalName ='Circular/killion'
            let l1=`../assets/${modalName}/odm_texturing/odm_textured_model_geo.mtl`
            let l2= `../assets/${modalName}/odm_texturing/odm_textured_model_geo.obj`
            // let l1=`../assets/${modalName}/calderon-mesh.mtl`
            // let l2= `../assets/${modalName}/calderon-mesh.obj`
            this.loadModel2(loader,modalName,l1,l2)

        } else {
            loader.load(this.currentDesign?.model, (gltf) => {
                const root = gltf.scene;
                this.setModel(root)

                gltf.scene.traverse((child) => {
                    this.modelMeshs.push(child);
                    VARS.Meshes.push(child)
                });

                this.scene.add(root);
            });
        }
    }

    loadModel2(mtlLoader,modalName,l1,l2) {
       mtlLoader.load(l1, (materials) => {
           materials.preload();
           const objLoader = new OBJLoader();
           objLoader.setMaterials(materials);
           objLoader.load(l2, (object) => {
               object.traverse((child) => {
                   this.modelMeshs.push(child);
                   // VARS.Meshes.push(child)
               });
               this.setModel(object)
               this.scene.add(object);
           });
       });
   }

    setModel(root){

        let bbox = new THREE.Box3().setFromObject(root);

        this.boxMinz = bbox.min.z;
        root.position.z -= this.boxMinz;

    }

    //box for testing purpose
    addBox(bbox){
        const size = bbox.getSize(new THREE.Vector3());
        const center = bbox.getCenter(new THREE.Vector3());

        // Create a helper box around the model
        const boxGeometry = new THREE.BoxGeometry(size.x, size.y, size.z);
        const boxMaterial = new THREE.MeshBasicMaterial({ color: 0xff0000, wireframe: true });
        const helperBox = new THREE.Mesh(boxGeometry, boxMaterial);
        helperBox.position.copy(center);

        helperBox.position.z -= this.boxMinz;
        this.scene.add(helperBox);

    }

    addFloor() {
        const geometry = new THREE.BoxGeometry(130, 130, 0);
        const address = (DEV_MOD) ? MODEL_URL : this.currentDesign?.address_image;
        const texture = new THREE.TextureLoader().load(address);
        const material = new THREE.MeshStandardMaterial({ map: texture, roughness: 0.8 });
        const cube = new THREE.Mesh(geometry, material);
        cube.userData.name = 'ground';
        cube.position.set(0, 0, 0);
        // cube.position.set(0,0, this.boxMinz-3);
        this.scene.add(cube);



        VARS.mapTile=cube;
        const renderTarget = new THREE.WebGLRenderTarget(window.innerWidth, window.innerHeight);
        renderTarget.texture.encoding = THREE.sRGBEncoding;
    }

    addLights() {
        const directionalLight = new THREE.DirectionalLight(0xffffff, 0.75);
        directionalLight.position.set(0, 0, -2);
        this.scene.add(directionalLight);
    }

    onProgress(url, loaded, total) {
        let progress = (loaded / total) * 100;
        this.setModelProgress(progress.toFixed(2));
    }

    onError(url, item, load) {
        console.error(`Error: ${url}`);
    }

}
