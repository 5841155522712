import { message } from "antd";
export const API_URL = process.env.REACT_APP_API_URL

export const DEV_MOD = process.env.REACT_APP_DEV_MODE==="true"

export const LAT = "42.80036711341816"

export const LNG = "-71.19690712541342";
export const MODEL_URL = `https://maps.googleapis.com/maps/api/staticmap?center=${LAT},${LNG}&size=600x600&scale=2&zoom=19&maptype=satellite&key=AIzaSyAo1viD-Ut0TzXTyihevwuf-9tv_J3dPa0`


export const handleErrors = (err) => {
  if (err && err.response) {
    if (err.response.data.error) {
      message.error(err.response.data.error);
    } else {
      let errors = err.response.data.errors;
      Object.keys(errors).map((key) => {
        let errorValue = errors[key];
        message.error(errorValue);
      });
    }
  }
};

export function convertDMSToDD(dmsLat, latRef, dmsLng, lngRef) {
  const parseDMSString = (dmsString) => {
    const [numerator, denominator] = dmsString.split('/').map(Number);
    return denominator !== 0 ? numerator / denominator : numerator;
  };

  // Helper function to convert DMS to decimal degrees
  const dmsToDecimal = (dms, ref) => {
    if (!Array.isArray(dms) || dms.length !== 3 || !ref) {
      console.error('Invalid DMS or reference:', dms, ref);
      return NaN;
    }

    const degrees = parseDMSString(dms[0]);
    const minutes = parseDMSString(dms[1]) / 60;
    const seconds = parseDMSString(dms[2]) / 3600;
    let decimal = degrees + minutes + seconds;

    if (ref === 'S' || ref === 'W') {
      decimal *= -1;
    }

    if (isNaN(decimal)) {
      console.error('Conversion resulted in NaN:', dms, ref);
    }

    return decimal;
  };

  // Convert the latitude and longitude
  const lat = dmsToDecimal(dmsLat, latRef);
  const lng = dmsToDecimal(dmsLng, lngRef);

  // Return the converted latitude and longitude
  if (isNaN(lat) || isNaN(lng)) {
    console.error('Invalid coordinates:', lat, lng);
  }
  return { latitude: lat, longitude: lng };
}

export const bytesToSize = (bytes) => {
  const kilobyte = 1024;
  const megabyte = kilobyte * 1024;

  if (bytes < kilobyte) {
    return bytes + ' B';
  } else if (bytes < megabyte) {
    return (bytes / kilobyte).toFixed(2) + ' KB';
  } else {
    return (bytes / megabyte).toFixed(2) + ' MB';
  }
};

//download image url and filenamee to download image
export const downloadImage = (blob, fileName) => {
  const link = document.createElement("a");
  link.href = blob;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};