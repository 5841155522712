import * as THREE from 'three'
import proj4 from "proj4";
import {VARS} from "../Global/variables";
import {DEV_MOD} from "../../helpers/CONSTANT";

export function calculatePitch(polygon) {
    if (polygon.length === 3) {
        return getTrianglePitch(polygon);
    }

    else{
        let midpoints = [];

        // Calculate midpoints of all sides
        for (let i = 0; i < polygon.length; i++) {
            let midx = (polygon[i].point1.x + polygon[i].point2.x) / 2;
            let midy = (polygon[i].point1.y + polygon[i].point2.y) / 2;
            let midz = (polygon[i].point1.z + polygon[i].point2.z) / 2;
            midpoints.push([midx, midy, midz]);
        }

        let pitches = [];
        let P1 = null;
        let P2 = null;

        // Iterate through all midpoints
        for (let i = 0; i < midpoints.length; i++) {
            let x_opp, y_opp, z_opp;

            // Find the opposite vertex for the midpoint
            // The opposite vertex is generally the one that is not part of the side that defines the midpoint
            if (i < midpoints.length - 1) {
                x_opp = polygon[i + 1].point1.x;
                y_opp = polygon[i + 1].point1.y;
                z_opp = polygon[i + 1].point1.z;
            } else {
                x_opp = polygon[0].point1.x;
                y_opp = polygon[0].point1.y;
                z_opp = polygon[0].point1.z;
            }

            // Create vectors for pitch calculation
            P1 = new THREE.Vector3(midpoints[i][0], midpoints[i][1], midpoints[i][2]);
            P2 = new THREE.Vector3(x_opp, y_opp, z_opp);

            // Avoid degenerate cases where points are too close or identical
            if (P1.equals(P2)) continue;

            let rise = Math.abs(P1.z - P2.z);  // The height difference
            let run = Math.sqrt((P1.x - P2.x) ** 2 + (P1.y - P2.y) ** 2);  // The horizontal distance

            // Handle division by zero (if run is zero, pitch is vertical)
            let pitch = (run === 0) ? Math.PI / 2 : Math.atan(rise / run);
            let pitchDeg = round(radians_to_degrees(pitch), 6);

            pitches.push(pitchDeg);
        }

        // Sort pitches to find the steepest slope
        pitches.sort((a, b) => b - a);
        let pitch =  pitches.length > 0 ? pitches[0] : null;
        pitch = round(pitch, 2);
        let res= prevPitch(polygon)
        pitch = pitch < res.pitch_angle ? res.pitch_angle : pitch;
        pitch = Math.round(pitch);


        // Return the steepest pitch and corresponding points
        return {
            pitch_angle: pitch,
            p1: res.p1,
            p2: res.p2,
        }
    }

}

function prevPitch(polygon){

    let P1 = null;
    let maxz = -999;
    let minz = 999;
    let maxp = null;
    let minp = null;
    let maxline = null;
    let minline = null;
    let P2 = null;
    let minI=null;


    for(let i=0;i<polygon.length;i++){
        let x = (polygon[i].point1.x+polygon[i].point2.x)/2
        let y = (polygon[i].point1.y+polygon[i].point2.y)/2
        let z = (polygon[i].point1.z+polygon[i].point2.z)/2
        if(maxz<z){
            maxp = [x,y,z];
            maxz = z;
            maxline = polygon[i];
        }
        if(minz>z){
            minp = [x,y,z]
            minz = z;
            minline = polygon[i];
            minI=i;
        }
    }


    if( minline.vertexId1 === maxline.vertexId2 ) {
        minline = polygon[minI+1];

    }else if(minline.vertexId2 === maxline.vertexId1){
        minline = polygon[minI-1];
    }


    maxline = new THREE.Line3(maxline.point1,maxline.point2);
    minline = new THREE.Line3(minline.point1,minline.point2);


    P1 = new THREE.Vector3();
    maxline.getCenter(P1);
    P2 = new THREE.Vector3();
    minline.closestPointToPoint(P1,false,P2);

    let reference_A = new THREE.Vector3();
    let reference_B = new THREE.Vector3();
    reference_A.copy(P1);
    reference_B.copy(P2);
    // testingPitch(P1,P2)

    let greenOpposite = Math.abs(P1.z - P2.z);
    let greenAdjacent = Math.sqrt(Math.pow(P1.x - P2.x,2) + Math.pow(P1.y - P2.y, 2));
    let pitch = Math.atan2(greenOpposite, greenAdjacent);


    pitch = round(radians_to_degrees(pitch), 2);


    return {pitch_angle: pitch,p1:P1,p2:P2}
}

export function getTrianglePitch(polygon) {
    let midpoints = [];
    for (let i = 0; i < polygon.length; i++) {
        let midx = (polygon[i].point1.x + polygon[i].point2.x) / 2;
        let midy = (polygon[i].point1.y + polygon[i].point2.y) / 2;
        let midz = (polygon[i].point1.z + polygon[i].point2.z) / 2;
        midpoints.push([midx, midy, midz])
    }
    // get three oposing vertex
    let x1 = -midpoints[0][0] + midpoints[1][0] + midpoints[2][0];
    let y1 = -midpoints[0][1] + midpoints[1][1] + midpoints[2][1];
    let x2 = midpoints[0][0] - midpoints[1][0] + midpoints[2][0];
    let y2 = midpoints[0][1] - midpoints[1][1] + midpoints[2][1];
    let x3 = midpoints[0][0] + midpoints[1][0] - midpoints[2][0];
    let y3 = midpoints[0][1] + midpoints[1][1] - midpoints[2][1];
    let z1 = 0;
    let z2 = 0;
    let z3 = 0;
    for (let i = 0; i < polygon.length; i++) {
        if (round(polygon[i].point1.x, 3) == round(x1, 3) && round(polygon[i].point1.y, 3) == round(y1, 3)) {
            z1 = polygon[i].point1.z;
        }
        if (round(polygon[i].point2.x, 3) == round(x1, 3) && round(polygon[i].point2.y, 3) == round(y1, 3)) {
            z1 = polygon[i].point2.z;
        }
        if (round(polygon[i].point1.x, 3) == round(x2, 3) && round(polygon[i].point1.y, 3) == round(y2, 3)) {
            z2 = polygon[i].point1.z;
        }
        if (round(polygon[i].point2.x, 3) == round(x2, 3) && round(polygon[i].point2.y, 3) == round(y2, 3)) {
            z2 = polygon[i].point2.z;
        }
        if (round(polygon[i].point1.x, 3) == round(x3, 3) && round(polygon[i].point1.y, 3) == round(y3, 3)) {
            z3 = polygon[i].point1.z;
        }
        if (round(polygon[i].point2.x, 3) == round(x3, 3) && round(polygon[i].point2.y, 3) == round(y3, 3)) {
            z3 = polygon[i].point2.z;
        }
    }

    const { P1: P1_1, P2: P2_1 } = createPoints(midpoints[0], z1, x1, y1);
    const pitch_1 = calculateTriPitch(P1_1, P2_1);

    const { P1: P1_2, P2: P2_2 } = createPoints(midpoints[1], z2, x2, y2);
    const pitch_2 = calculateTriPitch(P1_2, P2_2);

    const { P1: P1_3, P2: P2_3 } = createPoints(midpoints[2], z3, x3, y3);
    const pitch_3 = calculateTriPitch(P1_3, P2_3);

    if (pitch_1 >= pitch_2 && pitch_1 >= pitch_3) {
        return {pitch_angle: pitch_1, p1: P1_1, p2: P2_1};
    } else if (pitch_2 >= pitch_1 && pitch_2 >= pitch_3) {
        return {pitch_angle: pitch_2, p1: P1_2, p2: P2_2};
    } else {
        return {pitch_angle: pitch_3, p1: P1_3, p2: P2_3};
    }
}

function createPoints(midpoint, z, x, y) {
    let P1 = null;
    let P2 = null;
    if (midpoint[2] < z) {
        P1 = new THREE.Vector3(x, y, z);
        P2 = new THREE.Vector3(midpoint[0], midpoint[1], midpoint[2]);
    } else {
        P1 = new THREE.Vector3(midpoint[0], midpoint[1], midpoint[2]);
        P2 = new THREE.Vector3(x, y, z);
    }
    return { P1, P2 };
}

function calculateTriPitch(P1, P2) {
    // Compute rise and run
    let C = new THREE.Vector3(P1.x, P1.y, P2.z);
    let rise = Math.sqrt(P1.sub(C).lengthSq());
    let run = Math.sqrt(P2.sub(C).lengthSq());

    // Calculate pitch based on rise and run
    let pitch;
    if (rise !== 0 && run !== 0) {
        pitch = rise / run;
        pitch = Math.atan(pitch);
    } else {
        pitch = 0;
    }

    return round(radians_to_degrees(pitch), 0);
}

export function round(value, precision) {
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
}

export function radians_to_degrees(rad) {
    return (rad * 180.0) / Math.PI;
}

export function getPolyArea(polygon) {

    let poly = [];
    for (let i = 0; i < polygon.length; i++) {
        poly.push([polygon[i].point1.x, polygon[i].point1.y, polygon[i].point1.z]);
    }
    if (poly.length < 3) {

        return 0;
    } else {
        let total = [0, 0, 0]
        for (let i = 0; i < poly.length; i++) {
            var vi1 = poly[i];
            if (i === poly.length - 1) {
                var vi2 = poly[0];
            } else {
                var vi2 = poly[i + 1];
            }
            let prod = cross(vi1, vi2);
            total[0] = total[0] + prod[0];
            total[1] = total[1] + prod[1];
            total[2] = total[2] + prod[2];
        }
        let result = dot(total, unit_normal(poly[0], poly[1], poly[2]));

        return Math.abs(result / 2) * 10.7639;
    }
}

function dot(a, b) {
    return a[0] * b[0] + a[1] * b[1] + a[2] * b[2];
}

// cross product of vectors a and b
function cross(a, b) {
    let x = (a[1] * b[2]) - (a[2] * b[1]);
    let y = (a[2] * b[0]) - (a[0] * b[2]);
    let z = (a[0] * b[1]) - (a[1] * b[0]);
    return [x, y, z];
}

function unit_normal(a, b, c) {
    let x = det([
        [1, a[1], a[2]],
        [1, b[1], b[2]],
        [1, c[1], c[2]]
    ]);
    let y = det([
        [a[0], 1, a[2]],
        [b[0], 1, b[2]],
        [c[0], 1, c[2]]
    ]);
    let z = det([
        [a[0], a[1], 1],
        [b[0], b[1], 1],
        [c[0], c[1], 1]
    ]);
    let magnitude = Math.pow(Math.pow(x, 2) + Math.pow(y, 2) + Math.pow(z, 2), 0.5);
    return [x / magnitude, y / magnitude, z / magnitude];
}

function det(a) {
    return a[0][0] * a[1][1] * a[2][2] + a[0][1] * a[1][2] * a[2][0] + a[0][2] * a[1][0] * a[2][1] - a[0][2] * a[1][1] * a[2][0] - a[0][1] * a[1][0] * a[2][2] - a[0][0] * a[1][2] * a[2][1];
}

export function getAzimuth(P1, P2) {

    let {originXY, proj} = getModelData();

    let lonlat1 = proj.inverse([originXY[0] - P1.x, originXY[1] - P1.y]);
    let lonlat2 = proj.inverse([originXY[0] - P2.x, originXY[1] - P2.y]);
    let lat1 = degrees_to_radians(lonlat1[1]);
    let lat2 = degrees_to_radians(lonlat2[1]);
    let lon1 = degrees_to_radians(lonlat1[0]);
    let lon2 = degrees_to_radians(lonlat2[0]);
    let deltalon = lon2 - lon1;
    let y2 = Math.sin(deltalon) * Math.cos(lat2);
    let x2 = (Math.cos(lat1) * Math.sin(lat2)) - (Math.sin(lat1) * Math.cos(lat2) * Math.cos(deltalon));
    let azimth = radians_to_degrees(Math.atan2(y2, x2));
    if (azimth < 0) {
        azimth = 360 + azimth;
    }
    return (azimth);
}

function getModelData() {
    let projection = null;
    let originXY = null;
    if(DEV_MOD){
        //fionia modal cords
        originXY = [681184, 4585677]
        projection= "+proj=utm +zone=19 +datum=WGS84 +units=m +no_defs +type=crs"
    }
    else{
        let coordinates = VARS.Current_Project?.co_ordinates.split(" ");
        let x = parseInt(coordinates[0]);
        let y = parseInt(coordinates[1]);
        originXY = [x, y]
        projection = VARS.Current_Project.projection
    }

    let proj = proj4(projection)
    return {originXY, proj};
}

function degrees_to_radians(degrees) {
    var pi = Math.PI;
    return degrees * (pi / 180);
}


