import * as THREE from "three";
import {DrawingModes, ObstacleTypes, VARS} from "../Global/variables";
import SegmentManager from "../Segments/SegmentManager";
import ObstacleManager from "../Obstacles/ObstacleManager";
import KeyEventListener from "./KeyEventListener";
import RulerManager from "../Ruler/RulerManager";
import {OBSTACLE} from "../Global/materials";
import {createPanelGeometry, dropPanel, getPlaneIntersect} from "../Segments/Panels/panels";
import {sceneManager} from "../SetUps/SceneManager";

export class MouseEventHandlers {
    constructor(SceneManager, currentDrawingModeRef, obstacleTypeRef, updateDrawingMode,
                setPolygons, setObstacles, setDeleteConfirmationVisible, selectedIndices, setSelectedIndices,
                setPolygonDetails, modelMeshes, selectedPanels) {
        this.mousePosition = new THREE.Vector2();
        this.sceneManager = SceneManager;
        this.currentDrawingMode = currentDrawingModeRef.current;
        this.updateDrawingMode = updateDrawingMode;
        this.Mesh = modelMeshes;
        this.isDrawingStarted = false;
        this.shape = null;
        this.obstacleType = obstacleTypeRef.current;
        this.start = new THREE.Vector2();
        this.end = new THREE.Vector2();
        this.setPolygons = setPolygons;
        this.setObstacles = setObstacles
        this.selectedIndices = selectedIndices;
        this.setSelectedIndices = setSelectedIndices;
        this.setPolygonDetails = setPolygonDetails;
        this.selectedPanels = selectedPanels;
        this.panelTexture = null;
        const handleCompleteShape = () => {
            this.shape.completeShape(selectedIndices);
        }

        function undo() {
            this.shape.undo();
        }

        function redo() {
            this.shape.redo();
        }
        const cancelSegmentDrawing = () =>{
            this.stopDrawing();
        }

        const cancelDrawing = () => {

            if (obstacleTypeRef.current === ObstacleTypes.RECTANGLE) {
                OBSTACLE.box.visible = false;
                this.stopDrawing();
            } else if (obstacleTypeRef.current === ObstacleTypes.CIRCLE) {
                OBSTACLE.circle.visible = false;
                this.stopDrawing();
            }
        }

        function removeRuler() {
            if(this.shape){
                this.shape.removeRuler();
            }
            this.stopDrawing();
        }

        // Create a new key listener
        this.keyListener = VARS.keyListener;
        if (this.keyListener === undefined || this.keyListener == null) {
            this.keyListener = new KeyEventListener(
                handleCompleteShape.bind(this),
                this.currentDrawingMode,
                undo.bind(this),
                redo.bind(this),
                setDeleteConfirmationVisible.bind(this),
                this.selectedIndices,
                cancelDrawing.bind(this),
                removeRuler.bind(this),
                cancelSegmentDrawing.bind(this),
            );
        }
        else {
            this.keyListener.completeShape = handleCompleteShape.bind(this);
            this.keyListener.currentDrawingMode = this.currentDrawingMode;

            this.keyListener.undo = undo.bind(this);
            this.keyListener.redo = redo.bind(this);
            this.keyListener.selectedindices = this.selectedIndices;
            this.keyListener.canceldrawing = cancelDrawing.bind(this);
            this.keyListener.removeRuler = removeRuler.bind(this);
            this.keyListener.cancelSegmentDrawing= cancelSegmentDrawing.bind(this);
        }
        VARS.keyListener = this.keyListener;


    }

    createPanel(){
        if (!this.panelTexture) {
            // If the panel does not exist, create it
            const geometry = new THREE.BoxGeometry(130, 130, 0);
            const texture = new THREE.TextureLoader().load("/assets/SolarPanels/solar1.jpg");
            const material = new THREE.MeshStandardMaterial({ map: texture, roughness: 0.8 });
            this.panelTexture = new THREE.Mesh(geometry, material);
            this.panelTexture.renderOrder = 1;
            this.panelTexture.frustumCulled = false; // Prevent culling if it's causing issues
            sceneManager.scene.add(this.panelTexture);
        }
    }
    handleMouseDown(event) {

        if (event.which === 1 || event.button === 0) {
            this.getMousePosition(event)

            if(this.currentDrawingMode ===DrawingModes.PANEL){
                // if(this.panelTexture== null){
                //     this.createPanel()
                // }else{
                console.log(this.selectedPanels)
                    dropPanel();
                    this.panelTexture =null;
                // }

            }else {
                let intersections = this.getIntersections()
                if (intersections.length > 0) {
                    let point = intersections[0].point;
                    switch (this.currentDrawingMode) {

                        case DrawingModes.SEGMENT:
                            if (!this.isDrawingStarted) {
                                this.isDrawingStarted = true;
                                if (this.shape == null) {
                                    this.shape = new SegmentManager(
                                        this.sceneManager.scene,
                                        this.stopDrawing,
                                        this.setPolygons,
                                        this.selectedIndices,
                                        this.setSelectedIndices,
                                        this.setPolygonDetails
                                    );
                                }
                            }

                            this.shape.handleMouseDown(point)
                            break;

                        case DrawingModes.OBSTACLE:
                            if (!this.isDrawingStarted) {
                                this.isDrawingStarted = true;
                                this.shape = new ObstacleManager(
                                    this.sceneManager.scene,
                                    this.sceneManager.camera,
                                    this.obstacleType,
                                    this.stopDrawing,
                                    this.setObstacles,
                                    this.selectedIndices,
                                );
                            }

                            // Don't kn why it doesn't work with this.mousePosition
                            this.start = this.getPoints(this.start);
                            this.shape.handleMouseDown(this.start)
                            break;

                        case DrawingModes.RULER:
                            if (!this.isDrawingStarted) {
                                this.isDrawingStarted = true;
                                this.shape = new RulerManager(
                                    this.sceneManager.scene,
                                    this.stopDrawing,
                                );
                            }
                            this.shape.handleMouseDown(point)
                            break;


                        case DrawingModes.NONE:
                            // this.shape = null;
                            break;
                    }
                }
            }
        }

    }

    handleMouseMove(event) {
        this.getMousePosition(event)

        if(this.currentDrawingMode ===DrawingModes.PANEL){
            // if(this.panelTexture== null){
            //     this.createPanel()
            // }
            // if (this.currentDrawingMode === DrawingModes.PANEL) {
             getPlaneIntersect(this.mousePosition,this.selectedPanels);
            // }
        }
        else {
            let intersections = this.getIntersections()
            if (intersections.length > 0) {
                let point = intersections[0].point;
                switch (this.currentDrawingMode) {
                    case DrawingModes.SEGMENT:
                        if (this.shape == null) {
                            this.shape = new SegmentManager(
                                this.sceneManager.scene,
                                this.stopDrawing,
                                this.setPolygons,
                                this.selectedIndices,
                                this.setSelectedIndices,
                                this.setPolygonDetails
                            );
                        }
                        this.shape.handleMouseMove(point)
                        break;
                    case DrawingModes.OBSTACLE:
                        if (this.isDrawingStarted) {
                            this.end = this.getPoints(this.end)
                            this.shape.handleMouseMove(this.end)
                        }

                        break;
                    case DrawingModes.RULER:
                        if (this.isDrawingStarted) {
                            this.shape.handleMouseMove(point)
                        }
                        break;
                    default:
                        break;
                }

            }
        }
    }

    handleMouseUp(event) {

    }

    stopDrawing = () => {
        this.isDrawingStarted = false;
        this.updateDrawingMode(DrawingModes.NONE);
    }

    getMousePosition(event) {
        this.mousePosition.x = (event.clientX / window.innerWidth) * 2 - 1;
        const offsetY = event.clientY - 82.6;
        this.mousePosition.y = -(offsetY / this.sceneManager.height) * 2 + 1
    }

    getPoints(point) {
        // since it doesn't work with this.mousePosition we use this
        point.x = this.mousePosition.x;
        point.y = this.mousePosition.y;
        return point;
    }

    getIntersections() {
        let rayCaster = new THREE.Raycaster();
        rayCaster.setFromCamera(this.mousePosition, this.sceneManager.camera);
        return rayCaster.intersectObjects(this.Mesh, false);
    }



}